* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

*::selection {
  color: white;
  background: #b20e17;  
}

body {
  margin: 0;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 

  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  background-color: #09142f;
}

body .display-none {
  display: none;
}